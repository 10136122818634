import React, { useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import styled from "styled-components";
import { COLORS } from "../../utils/color";
import Text from "../Text/Text";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const Offer = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <OfferView>
            <div data-aos="fade-up" data-aos-duration="800">
                <Text
                    text={t("franchise_title")}
                    textSize="forty"
                    textAlign="center"
                />
            </div>
            <div className="rowBox">
                <div
                    className="leftBox"
                    data-aos="fade-right"
                    data-aos-duration="800"
                >
                    <Text text={t("franchise_one")} textSize="twentyTwo" />
                    <Text text={t("franchise_two")} textSize="twentyTwo" />
                    <Text text={t("franchise_three")} textSize="twentyTwo" />
                    <Text text={t("franchise_four")} textSize="twentyTwo" />
                    <Text text={t("franchise_five")} textSize="twentyTwo" />
                    <Text text={t("franchise_six")} textSize="twentyTwo" />
                </div>
                <div className="rightBox">
                    <div className="offerBox">
                        <div
                            className="offerItem"
                            data-aos="fade-left"
                            data-aos-duration="800"
                        >
                            <div className="offerTitleBox">
                                <FaCheckCircle
                                    size={32}
                                    color={COLORS.orange}
                                />
                                <Text
                                    text={t("franchise_about_one")}
                                    textSize="twentyTwo"
                                />
                            </div>
                            <Text
                                text={t("franchise_about_one_text")}
                                textSize="sixteen"
                                lineHeight={16}
                            />
                        </div>
                        <div
                            className="offerItem"
                            data-aos="fade-right"
                            data-aos-duration="800"
                        >
                            <div className="offerTitleBox">
                                <FaCheckCircle
                                    size={32}
                                    color={COLORS.orange}
                                />
                                <Text
                                    text={t("franchise_about_two")}
                                    textSize="twentyTwo"
                                />
                            </div>
                            <Text
                                text={t("franchise_about_two_text")}
                                textSize="sixteen"
                                lineHeight={16}
                            />
                        </div>
                        <div
                            className="offerItem"
                            data-aos="fade-left"
                            data-aos-duration="800"
                        >
                            <div className="offerTitleBox">
                                <FaCheckCircle
                                    size={32}
                                    color={COLORS.orange}
                                />
                                <Text
                                    text={t("franchise_about_three")}
                                    textSize="twentyTwo"
                                />
                            </div>
                            <Text
                                text={t("franchise_about_three_text")}
                                textSize="sixteen"
                                lineHeight={16}
                            />
                        </div>
                        <div
                            className="offerItem"
                            data-aos="fade-right"
                            data-aos-duration="800"
                        >
                            <div className="offerTitleBox">
                                <FaCheckCircle
                                    size={32}
                                    color={COLORS.orange}
                                />
                                <Text
                                    text={t("franchise_about_four")}
                                    textSize="twentyTwo"
                                />
                            </div>
                            <Text
                                text={t("franchise_about_four_text")}
                                textSize="sixteen"
                                lineHeight={16}
                            />
                        </div>
                        <div
                            className="offerItem"
                            data-aos="fade-left"
                            data-aos-duration="800"
                        >
                            <div className="offerTitleBox">
                                <FaCheckCircle
                                    size={32}
                                    color={COLORS.orange}
                                />
                                <Text
                                    text={t("franchise_about_five")}
                                    textSize="twentyTwo"
                                />
                            </div>
                            <Text
                                text={t("franchise_about_five_text")}
                                textSize="sixteen"
                                lineHeight={16}
                            />
                        </div>
                        <div
                            className="offerItem"
                            data-aos="fade-right"
                            data-aos-duration="800"
                        >
                            <div className="offerTitleBox">
                                <FaCheckCircle
                                    size={32}
                                    color={COLORS.orange}
                                />
                                <Text
                                    text={t("franchise_about_six")}
                                    textSize="twentyTwo"
                                />
                            </div>
                            <Text
                                text={t("franchise_about_six_text")}
                                textSize="sixteen"
                                lineHeight={16}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="offerTypes">

            </div> */}
        </OfferView>
    );
};

export default Offer;

const OfferView = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    height: 100%;
    padding: 50px 10%;

    .rowBox {
        display: flex;
        align-items: center;
    }

    .leftBox {
        width: 40%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .rightBox {
        width: 60%;
    }

    .offerBox {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .offerItem {
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: #f3f3f3;
        padding: 10px;
        border-radius: 5px;
    }

    .offerTitleBox {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    @media (min-width: 120px) {
        padding: 50px 3%;
        gap: 20px;

        .rowBox {
            flex-direction: column;
            gap: 20px;
        }
        img {
            height: 350px;
        }
        .leftBox {
            width: 100%;
            padding-right: 0;
        }
        .rightBox {
            width: 100%;
        }
        .offerBox {
            grid-template-columns: 1fr;
        }
    }
    @media (min-width: 500px) {
        .offerBox {
            grid-template-columns: 1fr 1fr;
        }
    }
    @media (min-width: 767px) {
        img {
            height: 450px;
        }
    }
    @media (min-width: 992px) {
        padding: 50px 5%;
        .rowBox {
            flex-direction: row;
            gap: 0;
        }
        img {
            height: 550px;
        }
        .leftBox {
            width: 50%;
            padding-right: 20px;
        }
        .rightBox {
            width: 50%;
        }
    }
    @media (min-width: 1200px) {
        padding: 70px 10%;
        gap: 50px;
    }
`;

import React, { useEffect } from "react";
import styled from "styled-components";
import Text from "../Text/Text";
import { FaFileAlt, FaFileSignature } from "react-icons/fa";
import { MdVideoCameraFront } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { COLORS } from "../../utils/color";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const BuyStep = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <BuyStepView>
            <div data-aos="fade-up" data-aos-duration="1000">
                <Text
                    text={t("buy_franshize_steps")}
                    textSize="forty"
                    textAlign="center"
                />
            </div>
            <div className="steps" data-aos="fade-up" data-aos-duration="1000">
                <div className="stepItem">
                    <FaFileAlt size={64} color={COLORS.orange} />
                    <Text
                        text={t("buy_franshize_step_one")}
                        textSize="twenty"
                        textAlign="center"
                    />
                </div>
                <div className="stepItem">
                    <MdVideoCameraFront size={64} color={COLORS.orange} />
                    <Text
                        text={t("buy_franshize_step_two")}
                        textSize="twenty"
                        textAlign="center"
                    />
                </div>
                <div className="stepItem">
                    <HiUsers size={64} color={COLORS.orange} />
                    <Text
                        text={t("buy_franshize_step_three")}
                        textSize="twenty"
                        textAlign="center"
                    />
                </div>
                <div className="stepItem">
                    <FaFileSignature size={64} color={COLORS.orange} />
                    <Text
                        text={t("buy_franshize_step_four")}
                        textSize="twenty"
                        textAlign="center"
                    />
                </div>
            </div>
        </BuyStepView>
    );
};

export default BuyStep;

const BuyStepView = styled.div`
    height: 100%;
    padding: 70px 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .steps {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        margin-top: 50px;
    }

    .stepItem {
        background-color: ${COLORS.lightGray};
        padding: 20px;
        border-radius: 10px;
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: center;
    }

    @media (min-width: 120px) {
        padding: 50px 3%;
        .stepItem {
            padding: 10px;
        }
        .steps {
            grid-template-columns: 1fr;
        }
    }
    @media (min-width: 500px) {
        .steps {
            grid-template-columns: 1fr 1fr;
        }
    }
    @media (min-width: 767px) {
        .steps {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    @media (min-width: 992px) {
        padding: 50px 5%;
        .stepItem {
            padding: 20px;
        }
    }
    @media (min-width: 1200px) {
        padding: 70px 10%;
    }
`;

import React, { useEffect } from "react";
import styled from "styled-components";
import { IMAGES } from "../../assets";
import { COLORS } from "../../utils/color";
import { FaEye, FaInstagram, FaUsers } from "react-icons/fa";
import Text from "../Text/Text";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { MdLocationOn } from "react-icons/md";

const About = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <AboutView id="about">
            <div data-aos="fade-up" data-aos-duration="800">
                <Text
                    text={t("about_us")}
                    textSize="forty"
                    textAlign="center"
                />
            </div>
            <div className="rowItem">
                <div className="leftBox">
                    <div
                        className="aboutTextBox"
                        data-aos="fade-right"
                        data-aos-duration="400"
                    >
                        {/* <Text text="Logo" textSize="forty" /> */}
                        <Text text={t("about")} textSize="forty" />
                        <Text text={t("about_one")} textSize="twenty" />
                        <Text text={t("about_two")} textSize="twenty" />
                        <Text text={t("about_three")} textSize="twenty" />
                    </div>
                    <div className="statics">
                        <div
                            className="staticsBox"
                            data-aos="fade-left"
                            data-aos-duration="500"
                        >
                            <div className="numberBox">
                                <Text
                                    text="80+"
                                    textSize="forty"
                                    color={COLORS.orange}
                                />
                                <FaUsers size={32} color={COLORS.orange} />
                            </div>
                            <Text text={t("more_workers")} textSize="sixteen" />
                        </div>
                        <div
                            className="staticsBox"
                            data-aos="fade-right"
                            data-aos-duration="500"
                        >
                            <div className="numberBox">
                                <Text
                                    text="347k"
                                    textSize="forty"
                                    color={COLORS.orange}
                                />
                                <FaInstagram size={32} color={COLORS.orange} />
                            </div>
                            <Text text={t("followers")} textSize="sixteen" />
                        </div>
                        <div
                            className="staticsBox"
                            data-aos="fade-left"
                            data-aos-duration="500"
                        >
                            <div className="numberBox">
                                <Text
                                    text="35k"
                                    textSize="forty"
                                    color={COLORS.orange}
                                />
                                <FaEye size={32} color={COLORS.orange} />
                            </div>
                            <Text text={t("views_number")} textSize="sixteen" />
                        </div>
                        <div
                            className="staticsBox"
                            data-aos="fade-right"
                            data-aos-duration="500"
                        >
                            <div className="numberBox">
                                <Text
                                    text="16"
                                    textSize="forty"
                                    color={COLORS.orange}
                                />
                                <MdLocationOn size={32} color={COLORS.orange} />
                            </div>
                            <Text text={t("branchs")} textSize="sixteen" />
                        </div>
                    </div>
                </div>
                <div
                    className="rightBox"
                    data-aos="fade-left"
                    data-aos-duration="400"
                >
                    <img loading="lazy" src={IMAGES.about} alt="about" />
                </div>
            </div>
        </AboutView>
    );
};

export default About;

const AboutView = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 50px 10%;
    gap: 20px;

    .rowItem {
        display: flex;
        align-items: center;
    }

    .leftBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        width: 50%;
        padding-right: 20px;
        /* height: 100%; */
    }
    .aboutTextBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }

    .aboutTitle {
        color: ${COLORS.blue};
        font-size: 36px;
    }

    .aboutText {
        color: ${COLORS.black};
        font-size: 18px;
    }

    .statics {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .staticsBox {
        padding: 10px;
        background-color: #f3f3f3;
        border-radius: 10px;
    }

    .numberBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .rightBox {
        display: flex;
        align-items: center;
        width: 50%;
    }
    img {
        width: 100%;
        height: 550px;
        border-radius: 10px;
        object-fit: cover;
    }

    @media (min-width: 120px) {
        padding: 50px 3%;
        gap: 20px;

        .rowItem {
            flex-direction: column;
            gap: 20px;
        }
        img {
            height: 350px;
        }
        .leftBox {
            width: 100%;
            padding-right: 0;
        }
        .rightBox {
            width: 100%;
        }
        .statics {
            grid-template-columns: 1fr;
        }
    }
    @media (min-width: 500px) {
        .statics {
            grid-template-columns: 1fr 1fr;
        }
    }
    @media (min-width: 767px) {
        img {
            height: 450px;
        }
    }
    @media (min-width: 992px) {
        padding: 50px 5%;
        .rowItem {
            flex-direction: row;
            gap: 0;
        }
        img {
            height: 550px;
        }
        .leftBox {
            width: 50%;
            padding-right: 20px;
        }
        .rightBox {
            width: 50%;
        }
    }
    @media (min-width: 1200px) {
        padding: 70px 10%;
        gap: 50px;
    }
`;

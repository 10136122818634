import React, { useEffect } from "react";
import { IMAGES } from "../../assets";
import styled from "styled-components";
import { COLORS } from "../../utils/color";
import Button from "../Button/Button";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import Text from "../Text/Text";

const Welcome = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <WelcomeView>
            <div className="above"></div>
            <div className="content">
                <div data-aos="fade-right" data-aos-duration="800">
                    <Text
                        text={t("welcome")}
                        textSize="seventy"
                        color={COLORS.white}
                        textAlign="center"
                        lineHeight={60}
                        className="benifitText"
                    />
                </div>
                <div
                    className="benifits"
                    data-aos="fade-left"
                    data-aos-duration="800"
                >
                    <div className="bItem">
                        <Text
                            text={t("benefit")}
                            textSize="twentyEight"
                            color={COLORS.white}
                            className="benifitText"
                        />
                        <Text
                            text={t("benefit_from")}
                            textSize="twentyEight"
                            color={COLORS.white}
                            className="middle"
                        />
                        <Text
                            text={t("benefit_to")}
                            textSize="twentyEight"
                            color={COLORS.white}
                            className="benifitText"
                        />
                    </div>
                    <div className="bItem">
                        <Text
                            text={t("all_situation")}
                            textSize="twentyEight"
                            color={COLORS.white}
                            className="benifitText"
                        />
                        <Text
                            text={t("full")}
                            textSize="twentyEight"
                            color={COLORS.white}
                            className="middle"
                        />
                        <Text
                            text={t("auto")}
                            textSize="twentyEight"
                            color={COLORS.white}
                            className="benifitText"
                        />
                    </div>
                    <div className="bItem">
                        <Text
                            text={t("customer")}
                            textSize="twentyEight"
                            color={COLORS.white}
                            className="benifitText"
                        />
                        <Text
                            text={t("always")}
                            textSize="twentyEight"
                            color={COLORS.white}
                            className="middle"
                        />
                        <Text
                            text={t("stable")}
                            textSize="twentyEight"
                            color={COLORS.white}
                            className="benifitText"
                        />
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="800">
                    <a href="#contact" className="href">
                        <Button title={t("contact")} textSize />
                    </a>
                </div>
            </div>
        </WelcomeView>
    );
};

export default Welcome;

const WelcomeView = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    background-image: url(${IMAGES.welcome});
    padding: 0 10%;
    background-size: cover;
    background-blend-mode: darken;

    .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .above {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .rightBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 5% 0 3%;
        width: 50%;
        height: 100%;
        background-color: ${COLORS.orange};
    }
    .brending {
        font-size: 40px;
        color: ${COLORS.white};
    }
    .welcome {
        font-size: 60px;
        color: ${COLORS.white};
        line-height: 70px;
    }

    .benifits {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;

        padding: 20px;
        margin: 20px 0;
        border-radius: 8px;
        backdrop-filter: blur(10px);
    }

    .bItem {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 6px;
    }

    .middle {
        display: inline-block;
        background-color: ${COLORS.orange};
        padding: 4px 10px;
        border-radius: 5px;
    }

    @media (min-width: 120px) {
        padding: 0 3%;
    }
    @media (min-width: 992px) {
        padding: 0 5%;
    }

    @media (min-width: 1200px) {
        padding: 0 10%;
    }
`;

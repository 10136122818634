import React from "react";
import styled from "styled-components";
import { COLORS } from "../../utils/color";
import Text from "../Text/Text";

type Props = {
    title?: string;
    onPress?: () => void;
    icon?: React.ReactNode;
    marginTop?: string;
    disabled?: boolean;
    border?: string;
    textSize?: boolean;
};

const Button: React.FC<Props> = ({
    title,
    onPress,
    icon,
    marginTop,
    disabled,
    border,
    textSize,
}) => {
    return (
        <ButtonView
            onClick={onPress}
            style={{
                marginTop: marginTop ? marginTop : 0,
                border: border ? border : "",
            }}
            disabled={disabled}
        >
            {icon && icon}
            <Text
                text={title as never}
                textSize={textSize ? "twentyEight" : "sixteen"}
                className="text"
            />
        </ButtonView>
    );
};

export default Button;

const ButtonView = styled.button`
    padding: 8px 20px;
    background-color: ${COLORS.orange};
    border: none;
    color: white;
    font-size: 18px;
    border-radius: 5px;
    max-width: 250px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    .text {
        color: ${COLORS.white} !important;
    }

    &:hover {
        color: ${COLORS.orange};
        background-color: ${COLORS.white};
    }

    &:hover .text {
        color: ${COLORS.orange} !important;
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

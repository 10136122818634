import React, { useState } from "react";
import i18n from "../../translations";
import styles from "./Translation.module.css";
import { MdLanguage } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Translation = () => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const path = window.location.pathname.slice(1, 3);
    const { t } = useTranslation();

    const toggle = () => {
        setShow((e) => !e);
    };

    const lngs = [
        {
            name: t("uz"),
            url: "uz",
            icon: "/uz.png",
        },
        {
            name: t("ru"),
            url: "ru",
            icon: "/ru.png",
        },
    ];

    const onCloseModal = (e: any) => {
        i18n.changeLanguage(e.url);
        setShow(false);
        navigate(`/${e.url}`);
    };
    return (
        <div className={styles.container}>
            <div
                className={styles.language}
                style={{
                    borderRadius: show ? "6px 6px 0 0" : "6px",
                }}
                onClick={toggle}
            >
                <MdLanguage className={styles.worldIcon} size={30} />
                <button className={styles.languageBtn}>
                    <p>{t(path)}</p>
                </button>
            </div>
            {show ? (
                <div className={styles.languageModal}>
                    {lngs.map((item, index) => {
                        return (
                            <button
                                key={index}
                                className={styles.languageBtnModal}
                                onClick={() => onCloseModal(item)}
                            >
                                <img
                                    width={20}
                                    src={item.icon}
                                    alt="Flag"
                                    loading="lazy"
                                />
                                <p>{item.name}</p>
                            </button>
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
};

export default Translation;

import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../screens/Home";
import NotFound from "../screens/NotFound";
import i18n from "../translations";

const AppNavigation = () => {
    const path = window.location.pathname;
    const pathSearch = window.location;

    useEffect(() => {
        switch (path) {
            case "/uz":
                relaodPath("uz", false);
                break;
            case "/ru":
                relaodPath("ru", false);
                break;
            case "/":
                relaodPath("uz", true);
                break;
            default:
                relaodPath("uz", false, path);
        }
    }, []);

    const relaodPath = (lang: string, reload: boolean, path?: string) => {
        i18n.changeLanguage(path ? path.slice(1, 3) : lang);
        window.history.pushState(
            {},
            "",
            `${path ? path : lang}${pathSearch.search}`
        );
        if (reload) window.location.reload();
    };
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:lang" element={<Home />} />
                <Route path="/:lang/*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
};

export default AppNavigation;

import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import OfferVariantItem from "../OfferVariantItem/OfferVariantItem";
import { OfferVariants } from "../../constants/database";
import { COLORS } from "../../utils/color";
import Text from "../Text/Text";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const OfferVarinats = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    const renderOffers = useCallback(() => {
        return OfferVariants.map((item) => {
            return <OfferVariantItem title={item.title} texts={item.texts} />;
        });
    }, []);

    return (
        <OfferVarinatsView id="offer">
            <div data-aos="fade-up" data-aos-duration="800">
                <Text
                    text={t("offer_title")}
                    textSize="forty"
                    textAlign={"center"}
                />
                <Text
                    text={t("what_can_we_give_help")}
                    textSize="eighteen"
                    textAlign={"center"}
                    margin="15px 0 0 0"
                />
            </div>
            <div
                className="variants"
                data-aos="fade-up"
                data-aos-duration="800"
            >
                {renderOffers()}
            </div>
            <div className="offerTypes">
                <div data-aos="fade-up" data-aos-duration="800">
                    <Text
                        text={t("we_have_offer")}
                        textSize="eighteen"
                        textAlign={"center"}
                        margin="25px 0 15px 0"
                    />
                </div>
                <div
                    className="typeSmall type"
                    data-aos="fade-right"
                    data-aos-duration="800"
                >
                    <Text
                        text={t("small")}
                        textSize="eighteen"
                        textAlign={"center"}
                        color={COLORS.white}
                    />
                </div>
                <div
                    className="typeMiddle type"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                >
                    <Text
                        text={t("middle")}
                        textSize="eighteen"
                        textAlign={"center"}
                        color={COLORS.white}
                    />
                </div>
                <div
                    className="typeHigh type"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                >
                    <Text
                        text={t("high")}
                        textSize="eighteen"
                        textAlign={"center"}
                        color={COLORS.white}
                    />
                </div>
            </div>
        </OfferVarinatsView>
    );
};

export default OfferVarinats;

const OfferVarinatsView = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 70px 10%;

    .variants {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        margin-top: 30px;
    }

    .offerTypes {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .type {
        background-color: ${COLORS.orange};
        padding: 5px 0;
        border-radius: 5px;
    }

    .typeSmall {
        width: 50%;
    }
    .typeMiddle {
        width: 75%;
    }
    .typeHigh {
        width: 100%;
    }

    @media (min-width: 120px) {
        padding: 50px 3%;
        .variants {
            grid-template-columns: 1fr;
        }
    }

    @media (min-width: 576px) {
        .variants {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media (min-width: 992px) {
        padding: 50px 5%;

        .variants {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media (min-width: 1200px) {
        padding: 70px 10%;
    }
`;

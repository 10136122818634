import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import {
    WithFranshizeData,
    WithoutFranshizeData,
} from "../../constants/database";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";
import { LuMinusCircle } from "react-icons/lu";
import { FaCheckCircle } from "react-icons/fa";
import { COLORS } from "../../utils/color";
import AOS from "aos";
import "aos/dist/aos.css";

const WithFranshize = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    const { t } = useTranslation();

    const renderWithFranshize = useCallback(() => {
        return WithFranshizeData.map((item) => {
            return (
                <div className="itemBox">
                    <div className="iconBox">
                        <FaCheckCircle color="#44f765" size={20} />
                    </div>
                    <Text
                        text={t(item)}
                        textSize="eighteen"
                        color={COLORS.white}
                    />
                </div>
            );
        });
    }, [t]);

    const renderWithoutFranshize = useCallback(() => {
        return WithoutFranshizeData.map((item) => {
            return (
                <div className="itemBox">
                    <div className="iconBox">
                        <LuMinusCircle color={COLORS.red} size={20} />
                    </div>
                    <Text text={t(item)} textSize="eighteen" />
                </div>
            );
        });
    }, [t]);

    return (
        <WithFranshizeView>
            <div data-aos="fade-up" data-aos-duration="1000">
                <Text
                    text={t("franshize_advantage")}
                    textSize="forty"
                    textAlign="center"
                />
            </div>
            <div className="rowBox" data-aos="fade-up" data-aos-duration="1000">
                <div className="leftBox">
                    <Text
                        text={t("without_franshize_title")}
                        textSize="twentyEight"
                        textAlign="center"
                        margin="0 0 10px 0"
                    />
                    {renderWithoutFranshize()}
                </div>
                <div className="rightBox">
                    <Text
                        text={t("with_franshize_title")}
                        textSize="twentyEight"
                        textAlign="center"
                        color={COLORS.white}
                        margin="0 0 10px 0"
                    />
                    {renderWithFranshize()}
                </div>
            </div>
        </WithFranshizeView>
    );
};

export default WithFranshize;

const WithFranshizeView = styled.div`
    height: 100%;
    padding: 70px 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .rowBox {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 50px;
    }

    .leftBox {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
        background-color: ${COLORS.lightGray};
    }

    .itemBox {
        display: flex;
        gap: 10px;
    }

    .iconBox {
        padding-top: 3px;
    }

    .rightBox {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
        background-color: ${COLORS.orange};
    }

    @media (min-width: 120px) {
        padding: 50px 3%;
        .rowBox {
            grid-template-columns: 1fr;
        }
    }

    @media (min-width: 767px) {
        .rowBox {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media (min-width: 992px) {
        padding: 50px 5%;
    }

    @media (min-width: 1200px) {
        padding: 70px 10%;
    }
`;

import React from "react";
import Text from "../Text/Text";
import { COLORS } from "../../utils/color";
import { FaTelegramPlane } from "react-icons/fa";
import styled from "styled-components";
import { PiPhone } from "react-icons/pi";
import { IMAGES } from "../../assets";

const Footer = () => {
    return (
        <FooterView>
            <a href="/" className="href">
                <img
                    loading="lazy"
                    className="logo"
                    src={IMAGES.logo}
                    alt="Logo"
                    width={200}
                />
            </a>
            <div className="content">
                <div className="contact">
                    <a className="href phone" href="tel:(+998) 88 702 5578">
                        <PiPhone size={24} color={COLORS.white} />
                        <Text
                            text="+998 88 702 5578"
                            textSize="eighteen"
                            color={COLORS.white}
                        />
                    </a>
                    <a
                        href="https://t.me/Franshiza_menejer"
                        className="href"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FaTelegramPlane size={32} color={COLORS.lightBlue} />
                    </a>
                </div>
            </div>
        </FooterView>
    );
};

export default Footer;

const FooterView = styled.div`
    background-color: ${COLORS.orange};
    border-top: 1px solid ${COLORS.white};
    padding: 20px 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    .content {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    .phone {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .navbar {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
    .contact {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    .href {
        color: ${COLORS.white};
        font-size: 18px;
    }
`;

import React from "react";
import Header from "../components/Header/Header";
import styled from "styled-components";
import Welcome from "../components/Welcome/Welcome";
import AboutView from "../components/About/AboutView";
import Offer from "../components/Offer/Offer";
import OfferVarinats from "../components/OfferVariants/OfferVarinats";
import BranchsBenifits from "../components/BranchsBenifits/BranchsBenifits";
import Conditions from "../components/Conditions/Conditions";
import BuyStep from "../components/BuyStep/BuyStep";
import Contact from "../components/Contact/Contact";
import Partners from "../components/Partners/Partners";
import Footer from "../components/Footer/Footer";
import WithFranshize from "../components/WithFranshize/WithFranshize";
import Future from "../components/Future/Future";

const Home = () => {
    return (
        <HomeView>
            <Header />
            <Welcome />
            <AboutView />
            <Offer />
            <Future />
            <OfferVarinats />
            <BranchsBenifits />
            <WithFranshize />
            <Conditions />
            <BuyStep />
            <Contact />
            <Partners />
            <Footer />
        </HomeView>
    );
};

export default Home;

const HomeView = styled.div``;

import React, { useCallback } from "react";
import styled from "styled-components";
import { COLORS } from "../../utils/color";
import Text from "../Text/Text";
import { FaCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";
type Props = {
    title?: string;
    texts?: string[];
};

const OfferVariantItem: React.FC<Props> = ({ title, texts }) => {
    const { t } = useTranslation();

    const renderTexts = useCallback(() => {
        return texts?.map((item) => {
            return (
                <div className="item">
                    <div className="check">
                        <FaCheck color={COLORS.orange} size={16} />
                    </div>
                    <Text text={t(item)} textSize="eighteen" />
                </div>
            );
        });
    }, [texts, t]);

    return (
        <OfferVariantItemView>
            <Text text={t(title as never)} textSize="twentyTwo" />
            {renderTexts()}
        </OfferVariantItemView>
    );
};

export default OfferVariantItem;

const OfferVariantItemView = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${COLORS.lightGray};
    border-radius: 20px;
    padding: 20px;
    gap: 10px;

    .item {
        display: flex;
        gap: 10px;
    }

    .check {
        padding-top: 5px;
    }

    @media (min-width: 120px) {
        padding: 10px;
    }
    @media (min-width: 992px) {
        padding: 20px;
    }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Text from "../Text/Text";
import { COLORS } from "../../utils/color";
import { FaPhone, FaTelegramPlane } from "react-icons/fa";
import Button from "../Button/Button";
import PhoneInputView from "../PhoneInput/PhoneInput";
import "react-phone-number-input/style.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import axios from "axios";
import { message } from "antd";

const Contact = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [numberError, setNumberError] = useState(null);

    const data = `Franshiza ariza%0A Name: ${name}%0A Phone Number: ${number}%0A `;

    const onChangeNumber = (value: string) => {
        if (value && isValidPhoneNumber(value)) {
            setNumberError(null);
            setNumber(value);
        } else {
            setNumberError(t("number_is_invalid") as never);
            return;
        }
    };

    const sendBot = async () => {
        if (isValidPhoneNumber(number)) {
            setNumberError(null);
        } else {
            setNumberError(t("number_is_invalid") as never);
            return;
        }
        await axios({
            method: "post",
            url: `https://api.telegram.org/bot8161728282:AAHraCKjdf1sA5Yc62ngMN1gn91NByMUr6g/sendMessage?chat_id=-1002287800452&text=${data}`,
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                message.success(t("thank_you"), 8);
            })
            .catch((err) => {
                message.error(`${err}`, 5);
            });
    };

    return (
        <ContactView id="contact">
            <div data-aos="fade-up" data-aos-duration="1000">
                <Text
                    text={t("contact_us")}
                    textSize="forty"
                    textAlign="center"
                />
            </div>
            <div
                className="contact"
                data-aos="fade-up"
                data-aos-duration="1000"
            >
                <div className="leftB">
                    <Text text={t("still_has_question")} textSize="twentyTwo" />
                    <Text
                        text={t("contact_us_")}
                        textSize="twentyTwo"
                        margin="0 0 10px 0"
                    />
                    <a
                        href="tel:(+998) 88 702 55 78"
                        className="href"
                        rel="noreferrer"
                    >
                        <div className="socialItem">
                            <FaPhone size={26} color={COLORS.blue} />
                            <Text
                                text="+998 88 702 55 78"
                                textSize="twentyTwo"
                            />
                        </div>
                    </a>
                    <a
                        href="https://t.me/Franshiza_menejer"
                        className="href"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className="socialItem">
                            <FaTelegramPlane
                                size={26}
                                color={COLORS.lightBlue}
                            />
                            <Text
                                text="@Franshiza_menejer"
                                textSize="twentyTwo"
                            />
                        </div>
                    </a>
                </div>
                <div className="rightB">
                    <Text
                        text={t("input_name_please")}
                        textSize="twentyTwo"
                        color={COLORS.white}
                    />
                    <input
                        className="name"
                        placeholder={t("input_name")}
                        color={COLORS.blue}
                        onChange={(e) => setName(e.target?.value as never)}
                        value={name}
                    />
                    <Text
                        text={t("enter_phone_number")}
                        textSize="twentyTwo"
                        color={COLORS.white}
                    />
                    <div>
                        <PhoneInputView
                            onChange={(e) => onChangeNumber(e)}
                            value={number}
                        />
                        <div className={"validation"}>
                            {numberError ? (
                                <Text
                                    text={numberError}
                                    color="#ff0101"
                                    textSize="fourteen"
                                />
                            ) : null}
                        </div>
                    </div>
                    <Button
                        title={t("send")}
                        marginTop="20px"
                        disabled={numberError !== null}
                        onPress={sendBot}
                        border="1px solid #fff"
                    />
                </div>
            </div>
        </ContactView>
    );
};

export default Contact;

const ContactView = styled.div`
    height: 100%;
    padding: 70px 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .contact {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 50px;
    }

    .leftB {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
        background-color: ${COLORS.lightGray};
    }

    .socialItem {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .rightB {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
        background-color: ${COLORS.orange};
    }

    .name {
        padding: 10px 10px;
        color: ${COLORS.blue};
        outline: none;
        border: none;
        border-radius: 5px;
        font-size: 16px;
    }
    .validation {
        position: absolute;
    }

    @media (min-width: 120px) {
        padding: 50px 3%;
        .contact {
            grid-template-columns: 1fr;
        }
    }

    @media (min-width: 767px) {
        .contact {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media (min-width: 992px) {
        padding: 50px 5%;
    }

    @media (min-width: 1200px) {
        padding: 70px 10%;
    }
`;

export const IMAGES = {
    logo: require("./images/Layer_x0020_1.png"),
    logo_orange: require("./images/Layer_x0020_1-1.png"),
    welcome: require("./images/welcome.webp"),
    orol: require("./images/orol.png"),
    showroom: require("./images/showroom.webp"),
    telefonchiuz: require("./images/telefonchi.uz.png"),
    experience: require("./images/experience.webp"),
    uzbegim: require("./images/uzbegim.jpg"),
    samarqand_darvoza: require("./images/samarqand_darvoza.webp"),
    about: require("./images/about.webp"),
    seller: require("./images/seller.png"),
    uzum: require("./images/Uzum_Nasiya-01.PNG"),
    alif: require("./images/alif.png"),
    iman: require("./images/iman.png"),
    zoodpay: require("./images/zoodpay.png"),
    anorbank: require("./images/Anorbank.png"),
    tbc: require("./images/TBC_Bank_logo.svg.png"),
    honor: require("./images/Honor-Logo.png"),
    samsung: require("./images/samsung.PNG"),
    mi: require("./images/Xiaomi_logo_(2021-).svg.png"),
    oppo: require("./images/oppo-logo.png"),
    tecno: require("./images/tecno.png"),
    infinix: require("./images/infinix-logo2.png"),
    vivo: require("./images/vivo-2019.png"),
    realme: require("./images/realme.png"),
    poco: require("./images/poco-logo-078EF70555-seeklogo.com.png"),
};

import React from "react";
import styled from "styled-components";
import { COLORS } from "../utils/color";
import Button from "../components/Button/Button";

const NotFound = () => {
    return (
        <NotFoundView>
            <h1>404 Not Found</h1>
            <a href="/" className="href">
                <Button
                    title="Asosiy sahifaga o'tish"
                    border={`1px solid ${COLORS.white}`}
                />
            </a>
        </NotFoundView>
    );
};

export default NotFound;

const NotFoundView = styled.div`
    width: "100%";
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${COLORS.orange};
    gap: 20px;

    h1 {
        color: #fff;
    }
`;

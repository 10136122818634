export const COLORS = {
    orange: "#FAA500",
    blue: "#193C8C",
    lightBlue: "#249AD6",
    red: "red",
    lightRed: "#D02C62",
    lightGray: "#f3f3f3",
    white: "white",
    black: "#141414",
    gray: "gray",
    textGray: "#6c6c6c",
    tomato: "tomato",
};

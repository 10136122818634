import React, { useEffect } from "react";
import styled from "styled-components";
import Text from "../Text/Text";
import { IMAGES } from "../../assets";
import { COLORS } from "../../utils/color";
import { PiHandCoinsFill } from "react-icons/pi";
import { GiTakeMyMoney } from "react-icons/gi";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const Conditions = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <ConditionsView>
            <div data-aos="fade-up" data-aos-duration="1000">
                <Text
                    text={t("condition_title")}
                    textSize="forty"
                    textAlign={"center"}
                />
            </div>
            <div className="rowBox" data-aos="fade-up" data-aos-duration="1000">
                <div className="boxL">
                    <Text text={t("one_time_pay")} textSize="twentyEight" />
                    <div className="royality">
                        <div className="royalityItem">
                            <Text
                                text="$5000"
                                textSize="twentyEight"
                                textAlign="center"
                                color={COLORS.white}
                            />
                            <Text
                                text={t("royality_region")}
                                textSize="twenty"
                            />
                        </div>
                        <div>
                            <Text
                                text="$10000"
                                textSize="twentyEight"
                                textAlign="center"
                                color={COLORS.white}
                            />
                            <Text text={t("royality_city")} textSize="twenty" />
                        </div>
                    </div>
                    <Text text={t("invests")} textSize="twentyEight" />
                    <div className="typeItem">
                        <img
                            loading="lazy"
                            src={IMAGES.orol}
                            alt="Ostrovok"
                            width={80}
                            height={80}
                        />
                        <Text text={t("invest_text_one")} textSize="eighteen" />
                        <Text
                            text="$15 000"
                            textSize="twentyTwo"
                            color={COLORS.white}
                        />
                    </div>
                    <div className="typeItem">
                        <img
                            loading="lazy"
                            src={IMAGES.telefonchiuz}
                            alt=""
                            width={80}
                            height={80}
                        />
                        <Text text={t("invest_text_two")} textSize="eighteen" />
                        <Text
                            text="$25 000"
                            textSize="twentyTwo"
                            color={COLORS.white}
                        />
                    </div>
                    <div className="typeItem">
                        <img
                            loading="lazy"
                            src={IMAGES.showroom}
                            alt=""
                            width={80}
                            height={80}
                        />
                        <Text
                            text={t("invest_text_three")}
                            textSize="eighteen"
                        />
                        <Text
                            text="$40 000"
                            textSize="twentyTwo"
                            color={COLORS.white}
                        />
                    </div>
                </div>
                <div className="boxR">
                    <Text
                        text={t("finance_result")}
                        textSize="twentyEight"
                        textAlign="center"
                    />
                    <Text
                        text={t("benifit_one_month")}
                        textSize="twentyEight"
                    />
                    <div className="typeItem">
                        <GiTakeMyMoney size={64} color={COLORS.orange} />
                        <Text
                            text={t("benifit_one_month_price")}
                            textSize="twenty"
                        />
                    </div>
                    <div className="typeItem">
                        <PiHandCoinsFill size={64} color={COLORS.orange} />
                        <Text text={t("benifit_back_text")} textSize="twenty" />
                    </div>
                    <Text text={t("royality")} textSize="twentyEight" />
                    <div className="royality">
                        <div className="royalityItem">
                            <Text
                                text="5%"
                                textSize="twentyEight"
                                textAlign="center"
                                color={COLORS.orange}
                            />
                            <Text
                                text={t("royality_region")}
                                textSize="twenty"
                            />
                        </div>
                        <div>
                            <Text
                                text="5%"
                                textSize="twentyEight"
                                textAlign="center"
                                color={COLORS.orange}
                            />
                            <Text text={t("royality_city")} textSize="twenty" />
                        </div>
                    </div>
                </div>
            </div>
        </ConditionsView>
    );
};

export default Conditions;

const ConditionsView = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 70px 10%;

    .rowBox {
        display: flex;
        margin-top: 50px;
        border-radius: 10px;
        overflow: hidden;
    }

    .boxL {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: ${COLORS.orange};
        padding: 20px;
    }
    .boxR {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: ${COLORS.lightGray};
        padding: 20px;
    }

    .typeItem {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 90%;

        img {
            object-fit: cover;
            border-radius: 4px;
        }
    }
    .royality {
        display: flex;
        gap: 20px;
    }

    @media (min-width: 120px) {
        padding: 50px 3%;
        .rowBox {
            flex-direction: column;
        }

        .boxL {
            width: 100%;
        }
        .boxR {
            width: 100%;
        }
    }

    @media (min-width: 767px) {
        .rowBox {
            flex-direction: row;
        }
        .boxL {
            width: 50%;
        }
        .boxR {
            width: 50%;
        }
    }

    @media (min-width: 992px) {
        padding: 50px 5%;
    }
    @media (min-width: 1200px) {
        padding: 70px 10%;
    }
`;

import React, { useEffect } from "react";
import styled from "styled-components";
import { IMAGES } from "../../assets";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../utils/color";
import AOS from "aos";
import "aos/dist/aos.css";

const Future = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <FutureView>
            <div className="rowBox">
                <div
                    className="leftBox"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                >
                    <div>
                        <Text textSize="forty" text={t("owner_name")} />
                        <Text textSize="twentyEight" text={t("owner_info")} />
                    </div>
                    <div className="experience">
                        <div className="year">
                            <Text
                                textSize="seventy"
                                text="16"
                                color={COLORS.white}
                                lineHeight={55}
                            />
                        </div>
                        <Text textSize="twentyEight" text={t("experince")} />
                    </div>
                    <Text textSize="twentyTwo" text={t("target_text")} />
                </div>
                <div
                    className="rightBox"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                >
                    <img
                        loading="lazy"
                        src={IMAGES.experience}
                        alt="Experience"
                        className="image"
                    />
                </div>
            </div>
        </FutureView>
    );
};

export default Future;

const FutureView = styled.div`
    padding: 70px 10%;

    .rowBox {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .experience {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .year {
        background-color: ${COLORS.blue};
        padding: 5px;
        border-radius: 10px;
    }

    .image {
        width: 100%;
        height: 450px;
        border-radius: 20px;
        object-fit: cover;
    }

    .leftBox {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    @media (min-width: 120px) {
        padding: 50px 3%;

        .rowBox {
            grid-template-columns: 1fr;
        }

        .image {
            height: 350px;
        }
    }

    @media (min-width: 576px) {
        .image {
            height: 400px;
        }
    }

    @media (min-width: 767px) {
        .image {
            height: 450px;
        }
    }

    @media (min-width: 992px) {
        padding: 50px 5%;

        .rowBox {
            grid-template-columns: 1fr 1fr;
        }
    }
    @media (min-width: 1200px) {
        padding: 70px 10%;
    }
`;

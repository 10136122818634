import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import BranchItem from "../BranchItem/BranchItem";
import Text from "../Text/Text";
import { BranchsStatistics } from "../../constants/database";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const BranchsBenifits = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    const renderBranchs = useCallback(() => {
        return BranchsStatistics.map((item, index) => {
            return (
                <BranchItem
                    key={index}
                    name={item.name}
                    getBenifit={item.getBenifitTime}
                    image={item.image}
                    location={item.location}
                    statics={item.statics as never}
                />
            );
        });
    }, []);

    return (
        <BranchsBenifitsView>
            <div data-aos="fade-up" data-aos-duration="1000">
                <Text
                    text={t("result_title")}
                    textSize="forty"
                    textAlign={"center"}
                />
                <Text
                    text={t("result_text")}
                    textSize="eighteen"
                    textAlign={"center"}
                    margin="15px 0 0 0"
                />
            </div>
            <div
                className="branchs"
                data-aos="fade-up"
                data-aos-duration="1000"
            >
                {renderBranchs()}
            </div>
        </BranchsBenifitsView>
    );
};

export default BranchsBenifits;

const BranchsBenifitsView = styled.div`
    height: 100%;
    padding: 70px 10%;

    .branchs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin-top: 50px;
    }

    @media (min-width: 120px) {
        padding: 50px 3%;

        .branchs {
            grid-template-columns: 1fr;
        }
    }
    @media (min-width: 767px) {
        .branchs {
            grid-template-columns: 1fr 1fr;
        }
    }
    @media (min-width: 992px) {
        padding: 50px 5%;
    }
    @media (min-width: 1200px) {
        padding: 70px 10%;
    }
`;

import React, { useCallback } from "react";
import { MdLocationOn } from "react-icons/md";
import styled from "styled-components";
import { COLORS } from "../../utils/color";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";

type Props = {
    name?: string;
    image?: string;
    getBenifit?: string;
    statics?: [];
    location?: string;
    instaLing?: string;
    tgLink?: string;
};

const BranchItem: React.FC<Props> = ({
    getBenifit,
    image,
    instaLing,
    location,
    name,
    statics,
    tgLink,
}) => {
    const { t } = useTranslation();
    const renderStatics = useCallback(() => {
        return statics?.map((item: any) => {
            return (
                <div className="staticItem">
                    <Text text={t(item.date)} textSize="sixteen" />
                    <Text text={t(item.salary)} textSize="sixteen" />
                </div>
            );
        });
    }, [statics, t]);

    return (
        <BranchItemView>
            <img className="image" src={image} alt="Branch" loading="lazy" />
            <Text
                text={t(name as never)}
                textSize="twentyTwo"
                margin="10px 0 5px 0"
            />
            <div className="dateBox">
                <Text text={t(getBenifit as never)} textSize="eighteen" />
            </div>
            <div className="statics">{renderStatics()}</div>
            <div className="info">
                <div className="location">
                    <MdLocationOn size={24} color={COLORS.blue} />
                    <Text text={t(location as never)} textSize="sixteen" />
                </div>
            </div>
        </BranchItemView>
    );
};

export default BranchItem;

const BranchItemView = styled.div`
    display: grid;
    padding: 20px;
    background-color: ${COLORS.lightGray};
    border-radius: 10px;

    .image {
        width: 100%;
        height: 350px;
        border-radius: 10px;
        object-fit: cover;
    }

    .dateBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }

    .statics {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .staticItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }

    .staticPrice {
        padding: 5px;
        background-color: ${COLORS.orange};
        color: ${COLORS.white};
        border-radius: 5px;
    }

    .info {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        align-self: flex-end;
    }

    .location {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .social {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    @media (min-width: 120px) {
        padding: 10px;

        .image {
            height: 300px;
        }
    }

    @media (min-width: 576px) {
        .image {
            height: 350px;
        }
    }

    @media (min-width: 992px) {
        padding: 20px;
    }
`;

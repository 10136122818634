import React from "react";
import PhoneInput from "react-phone-number-input";

type Props = {
    onChange: (e: any) => void;
    placeholder?: string;
    value?: string;
    error?: string;
};

const PhoneInputView: React.FC<Props> = ({
    onChange,
    placeholder,
    value,
    error,
}) => {
    return (
        <PhoneInput
            placeholder={placeholder}
            value={value}
            international={true}
            defaultCountry={"UZ" as never}
            onChange={onChange}
            error={error}
        />
    );
};

export default PhoneInputView;

import React, { useCallback } from "react";
import { PartnersLogo } from "../../constants/database";
import styled from "styled-components";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";

const Partners = () => {
    const { t } = useTranslation();
    const renderLogos = useCallback(() => {
        return PartnersLogo.map((item) => {
            return <img loading="lazy" src={item} alt="logo" />;
        });
    }, []);

    return (
        <PartnersView>
            <Text
                text={t("our_partners")}
                textSize="forty"
                textAlign={"center"}
            />
            <div className="logos">{renderLogos()}</div>
        </PartnersView>
    );
};

export default Partners;

const PartnersView = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    padding: 50px 10%;

    .logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        gap: 40px;
    }

    img {
        width: auto;
        height: 80px;
        object-fit: contain;
    }

    @media (min-width: 120px) {
        padding: 50px 3%;

        img {
            width: 160px;
        }
    }
    @media (min-width: 992px) {
        padding: 50px 5%;
    }
    @media (min-width: 1200px) {
        padding: 50px 10%;
    }
`;

import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { FaInstagram, FaPhone, FaTelegramPlane } from "react-icons/fa";
import Button from "../Button/Button";
import { COLORS } from "../../utils/color";
import Translation from "../Translation/Translation";
import { useTranslation } from "react-i18next";
import { HiMenu } from "react-icons/hi";
import { Drawer } from "antd";
import { MdClose } from "react-icons/md";
import Text from "../Text/Text";
import { IMAGES } from "../../assets";

const Header = () => {
    const { t } = useTranslation();
    const [scroll, setScroll] = useState(false);
    const [menu, setMenu] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 20) setScroll(true);
            else setScroll(false);
        });
    }, []);

    const closeDrawer = () => {
        setMenu(false);
    };

    const renderLogo = useCallback(() => {
        return scroll ? IMAGES.logo : IMAGES.logo_orange;
    }, [scroll]);

    return (
        <HeaderBox
            style={{
                backgroundColor: scroll ? COLORS.orange : "rgba(0, 0, 0, 0.1)",
            }}
        >
            <a href="/" className="href">
                <img src={renderLogo()} alt="Logo" width={200} />
            </a>
            <div className="rightBox">
                <div className="navbar">
                    <a href="#about" className="href">
                        <Text
                            text={t("about_us")}
                            textSize="eighteen"
                            color={COLORS.white}
                        />
                    </a>
                    <a href="#offer" className="href">
                        <Text
                            text={t("what_we_offer")}
                            textSize="eighteen"
                            color={COLORS.white}
                        />
                    </a>
                    <a href="#contact" className="href">
                        <Text
                            text={t("buy_franchise")}
                            textSize="eighteen"
                            color={COLORS.white}
                        />
                    </a>
                </div>
                <div className="contact">
                    <Translation />
                    <a className="href" href="tel:(+998) 88 702 5578">
                        <Button icon={<FaPhone />} title="+998 88 702 5578" />
                    </a>
                </div>
            </div>
            <div className="drawer">
                <div onClick={() => setMenu(true)}>
                    <HiMenu color={COLORS.white} size={32} />
                </div>
            </div>
            <Drawer
                width={"72vw"}
                className="drawerBox"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: COLORS.orange,
                    color: "#fff",
                }}
                headerStyle={{
                    justifyContent: "flex-start",
                    height: "8vh",
                    marginTop: "10px",
                    padding: "10px 10px 10px 15px",
                }}
                bodyStyle={{
                    padding: "0 0 0 20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                    marginTop: "20px",
                }}
                onClose={() => setMenu(false)}
                title={
                    <a href="/" className="href">
                        <img width={200} src={IMAGES.logo} alt="Logo" />
                    </a>
                }
                open={menu}
                closeIcon={<MdClose color={COLORS.white} size={32} />}
            >
                <a href="#about" className="href" onClick={closeDrawer}>
                    <Text
                        text={t("about_us")}
                        textSize="eighteen"
                        color={COLORS.white}
                    />
                </a>
                <a href="#offer" className="href" onClick={closeDrawer}>
                    <Text
                        text={t("what_we_offer")}
                        textSize="eighteen"
                        color={COLORS.white}
                    />
                </a>
                <a href="#contact" className="href" onClick={closeDrawer}>
                    <Text
                        text={t("buy_franchise")}
                        textSize="eighteen"
                        color={COLORS.white}
                    />
                </a>

                <Translation />
                <a className="href" href="tel:(+998) 88 702 5578">
                    <Button
                        icon={<FaPhone />}
                        title="+998 88 702 5578"
                        border={`1px solid ${COLORS.white}`}
                    />
                </a>
                <div style={{ display: "flex", gap: 20 }}>
                    <FaInstagram size={32} color={COLORS.lightRed} />
                    <FaTelegramPlane size={32} color={COLORS.lightBlue} />
                </div>
            </Drawer>
        </HeaderBox>
    );
};

export default Header;

const HeaderBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    width: 100%;
    position: fixed;
    top: 0;
    padding: 0 10%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 10;
    transition: all 0.2s ease-in-out;

    .rightBox {
        display: flex;
        align-items: center;
        gap: 50px;
    }

    .navbar {
        display: flex;
        gap: 20px;
    }

    .contact {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .drawer {
        display: none;
    }

    .href {
        color: ${COLORS.white};
        font-size: 18px;
    }

    @media (min-width: 120px) {
        padding: 0 3%;
        .rightBox {
            display: none;
        }
        .drawer {
            display: block;
        }
    }

    @media (min-width: 992px) {
        padding: 0 5%;

        .rightBox {
            display: flex;
            align-items: center;
            gap: 50px;
        }
        .drawer {
            display: none;
        }
    }
    @media (min-width: 1200px) {
        padding: 0 10%;

        .rightBox {
            display: flex;
            align-items: center;
            gap: 50px;
        }

        .navbar {
            display: flex;
            gap: 20px;
        }

        .contact {
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }
`;

import { IMAGES } from "../assets";

export const OfferVariants = [
    {
        title: "offer_provide",
        texts: ["offer_provide_one", "offer_provide_two"],
    },
    {
        title: "offer_workers",
        texts: [
            "offer_workers_one",
            "offer_workers_two",
            "offer_workers_three",
            "offer_workers_four",
        ],
    },
    {
        title: "offer_marketing",
        texts: [
            "offer_marketing_one",
            "offer_marketing_two",
            "offer_marketing_three",
        ],
    },
    {
        title: "offer_finance",
        texts: [
            "offer_finance_one",
            "offer_finance_two",
            "offer_finance_three",
            "offer_finance_four",
        ],
    },
];

export const BranchsStatistics = [
    {
        name: "branch_one_name",
        getBenifitTime: "branch_one_get_benifit",
        image: IMAGES.samarqand_darvoza,
        statics: [
            {
                date: "branch_one_benifit_date_one",
                salary: "branch_one_benifit_date_one_price",
            },
            {
                date: "branch_one_benifit_date_two",
                salary: "branch_one_benifit_date_two_price",
            },
            {
                date: "branch_one_benifit_date_three",
                salary: "branch_one_benifit_date_three_price",
            },
            {
                date: "branch_one_benifit_date_four",
                salary: "branch_one_benifit_date_four_price",
            },
        ],
        location: "branch_one_location",
        instaLink: "",
        tgaLink: "",
    },
    {
        name: "branch_two_name",
        getBenifitTime: "branch_two_get_benifit",
        image: IMAGES.uzbegim,
        statics: [
            {
                date: "branch_two_benifit_date_one",
                salary: "branch_two_benifit_date_one_price",
            },
            {
                date: "branch_two_benifit_date_two",
                salary: "branch_two_benifit_date_two_price",
            },
            {
                date: "branch_two_benifit_date_three",
                salary: "branch_two_benifit_date_three_price",
            },
            {
                date: "branch_two_benifit_date_four",
                salary: "branch_two_benifit_date_four_price",
            },
        ],
        location: "branch_two_location",
        instaLink: "",
        tgaLink: "",
    },
    // {
    //     name: "Samarqand Darvoza",
    //     getBenifitTime: "60 kun",
    //     statics: [
    //         {
    //             date: "Sentabr 2022",
    //             salary: "228 mln.so'm",
    //         },
    //         {
    //             date: "Dekabr 2022",
    //             salary: "350 mln.so'm",
    //         },
    //         {
    //             date: "Yanvar 2023",
    //             salary: "408 mln.so'm",
    //         },
    //     ],
    //     location: "Toshkent Samarqand darvoza",
    //     instaLink: "",
    //     tgaLink: "",
    // },
];

export const PartnersLogo = [
    IMAGES.uzum,
    IMAGES.alif,
    IMAGES.iman,
    IMAGES.zoodpay,
    IMAGES.anorbank,
    IMAGES.tbc,
    IMAGES.honor,
    IMAGES.samsung,
    IMAGES.mi,
    IMAGES.oppo,
    IMAGES.tecno,
    IMAGES.infinix,
    IMAGES.vivo,
    IMAGES.realme,
];

export const WithFranshizeData = [
    "with_franshize_one",
    "with_franshize_two",
    "with_franshize_three",
    "with_franshize_four",
    "with_franshize_five",
    "with_franshize_six",
    "with_franshize_seven",
    "with_franshize_eight",
];

export const WithoutFranshizeData = [
    "without_franshize_one",
    "without_franshize_two",
    "without_franshize_three",
    "without_franshize_four",
    "without_franshize_five",
    "without_franshize_six",
];
